.smallScreenPage {
    display: flex;
    gap: 0.625rem;
    padding: 1.5rem;
    height: 100vh;
    flex-direction: column;
    background: radial-gradient(91% 103.18% at 0% 0%, rgba(51, 43, 160, 0.50) 0%, rgba(51, 43, 160, 0.00) 100%),
                radial-gradient(161.97% 104.09% at 100% 26.04%, rgba(36, 137, 209, 0.50) 0%, rgba(36, 137, 209, 0.00) 100%),
                #07213C;
}

.errorContainer {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 0.625rem;
    padding: 0;
}

.errorTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    padding-bottom: 3.125rem;
}

.errorImage {
    position: relative;
    width: 4.16144rem;
    height: 3.42706rem;
    margin: 1.22rem 0;
}

.errorTextTitle {
    max-width: 21.875rem;
    font-size: 1.625rem;
    line-height: 2rem;
    font-family: 'Slussen Condensed', sans-serif;
    font-weight: 700;
    color: #ffffff;
}

.errorTextSubtitle {
    max-width: 19.688rem;
    font-size: 1rem;
    line-height: 1.25rem;
    font-family: Satoshi, sans-serif;
    color: #ffffff;
}