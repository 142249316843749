.title {
  line-height: 3.063rem;
  height: fit-content;
  width: 100%;
  font-size: 2.37rem;
  text-transform: uppercase;
  font-family: 'Slussen Condensed', sans-serif;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
}

.subtitle {
  font-size: 1.62rem;
  line-height: 2rem;
  font-family: Satoshi, sans-serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.titles {
  display: flex;
  flex-direction: column;
  justify-content: center;
}