.videoContainer {
  position: relative;
  width: "30vw";
  border: 1px solid rgba(152, 199, 255, 0.25);
  border-radius: 1em;
  overflow: hidden;
  display: flex;
  aspect-ratio: 480/854;
  justify-content: stretch;
}
