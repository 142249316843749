.playerNameWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.playerName {
  position: relative;
  display: inline-block;
  width: 5.7em;
  flex-shrink: 0;
  mix-blend-mode: normal;
  white-space: nowrap;
}

.stat {
  text-align: center;
  color: #FDFEFE;
  white-space: nowrap;
  mix-blend-mode: normal;
}

.markHeader {
  height: 1.5rem;
  width: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible; 
}
.stats {
  flex: 1;
  padding-right: 5em;
  padding-left: 11.3em;
  display: flex;
  justify-content: space-between;
}

.margins {
  width: 100%;
  position: relative;
  border-radius: 0.375rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  color: #FDFEFE;
}

.legend {
  width: 100%;
  position: relative;
  border-radius: 0.375rem;
  display: flex;
  flex-direction: row;
  padding-left: 3.9em;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  color: #FDFEFE;
  font-family: 'Satoshi', sans-serif;
}

