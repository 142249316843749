.leaderboards {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: 1.875em;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  position: relative;
}

.icon {
  width: 100%;
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  height: 30rem;
  flex-shrink: 0;
  margin-top: 5%;
}

.iconContainer {
  width: 13.5625rem;
  height: 14.0625rem;
}

.emptyStateIcon {
  width: 100%;
  height: 100%;
}

.mainText {
  font-size: 1.25rem;
  line-height: 2rem;
  text-transform: capitalize;
  font-family: Satoshi, sans-serif;
  font-weight: 700;
  color: #F2F4FC;
}

.text {
  position: relative;
  font-size: 1rem;
  line-height: 1.375rem;
  font-family: Satoshi, sans-serif;
  color: #ffffff;
  display: inline-block;
}

.emptyStateLeaderboardParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
}

.emptyStateContainer {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
}

.bluerSpace {
  height: 5.625rem;
}

.blur {
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(11px);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #1f3750;
  font-family: Satoshi, sans-serif;
}

.blurWithGradient {
  background: linear-gradient(0.61deg, #f4f8ff, rgba(244, 248, 255, 0)),
    linear-gradient(0deg, #f4f8ff, rgba(244, 248, 255, 0));
  backdrop-filter: blur(4px);
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #1f3750;
  font-family: Satoshi;
}
