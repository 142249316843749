.leaderboardPage {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid rgba(152, 199, 255, 0.25);
    border-radius: 1em;
    background:
        radial-gradient(91% 103.18% at 0% 0%, rgba(51, 43, 160, 0.50) 0%, rgba(51, 43, 160, 0.00) 100%),
        radial-gradient(161.97% 104.09% at 100% 26.04%, rgba(36, 137, 209, 0.50) 0%, rgba(36, 137, 209, 0.00) 100%),
        #07213C;
}