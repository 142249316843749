.errorContainer {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: 1.875em;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 0.625rem;
}

.errorText {
  width: 100%;
  position: relative;
  font-size: 1.625rem;
  line-height: 2rem;
  display: inline-block;
  font-family: 'Slussen Condensed', sans-serif;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  margin-bottom: 0.625rem
}