.errorContainer {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    padding: 1.875em;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
}

.loadingErrorImage {
    position: relative;
    width: 7.5rem;
    height: 7.5rem;
    margin-left: auto;
    margin-right: auto;
}