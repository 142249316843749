.header {
  width: 100%;
  position: relative;
  height: 9.375rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  color: #fff;
  padding: 0 1.875em;
}

.smallScreen {
  max-width: 25rem;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  gap: 2.93rem;
  margin: 0 auto;
}