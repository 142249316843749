.table {
  width: 100%;
  max-height: 54.375rem;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.38rem;
  text-align: center;
  font-size: 1rem;
  font-kerning: none;
  font-family: 'Satoshi', sans-serif;
  padding-top: 0.625rem;
}

.table::-webkit-scrollbar {
  width: 0.875rem;
}

.table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.875rem 0.875rem transparent;
  border: solid 0.25rem transparent;
}

.table::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0.875rem 0.875rem rgba(106, 169, 242, 0.25);
  border: solid 0.25rem transparent;
  border-radius: 0.875rem;
}

.table::-webkit-scrollbar-button {
  display: none;
}

.leaderboardRowWrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  gap: 0.625rem;
  min-height: 2.62rem;
  width: 100%;
  color: #ffffff;
}

.leaderboardRow {
  flex: 1;
  border-radius: 0.72281rem;
  border: 0.723px solid rgba(152, 199, 255, 0.25);
  background: rgba(22, 157, 255, 0.10);
  box-shadow: 0 16.625px 26.021px -29.635px rgba(106, 169, 242, 0.25) inset, 0 63.607px 45.537px -73.004px rgba(62, 100, 156, 0.25) inset;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 3.75rem 0 1.25rem;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  height: 100%;
  transition: all 1.5s ease-in-out;
  margin-right: 0.313rem;
}

.leaderboardEmptyRow {
  color: rgba(255, 255, 255, 0.60);
}

.iconTrophyWrapper {
  width: 1.875rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.iconTrophy {
  position: relative;
  height: 100%;
}

.stats {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.time {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 8.75rem;
  flex-shrink: 0;
  mix-blend-mode: normal;
}

.rank {
  position: relative;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.875rem;
  flex-shrink: 0;
  mix-blend-mode: normal;
  font-family: 'Satoshi', sans-serif;
  font-size: 1.125rem;
  color: #ffffff;
}

@keyframes flash {
  0% {
    box-shadow: 0 0 0 #00e2fe;
  }
  25% {
    box-shadow: 0 0 0.625rem #00e2fe;
  }
  50% {
    text-shadow: 1px 1px 2px #ffffff, 0 0 1em #ffffff, 0 0 2em #ffffff;
    box-shadow: 0 0 15px #00e2fe;
    background: rgba(0, 226, 254, 1);
    filter: blur(1px);
  }
  100% {
    box-shadow: initial;
  }
}
