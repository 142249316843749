.time {
  position: relative;
  font-weight: 500;
  display: inline-block;
  text-align: center;
  flex-shrink: 0;
  mix-blend-mode: normal;
  justify-content: center;
  overflow: visible; 
}

.stats {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding-left: 5em;
}

.markValue {
  height: 24px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible; 
}
  