.errorContainer {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    padding: 1.875em;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 0.625rem;
}

.errorImage {
    position: relative;
    width: 4.16144rem;
    height: 3.42706rem;
    margin: 1.22rem 0;
}

.errorTitle {
    width: 100%;
    position: relative;
    font-size: 1.625rem;
    line-height: 2rem;
    display: inline-block;
    font-family: 'Slussen Condensed', sans-serif;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
}

.errorSubtitle {
    position: relative;
    line-height: 1.25rem;
    font-family: Satoshi, sans-serif;
    font-size: 1rem;
    color: #ffffff;
    width:  34.375rem;
}